<template>
  <div class="viewTitle">Inference Rule Sets</div>

  <div class="card">
    <DataTable
      :value="enteries"
      sortField="category"
      :sortOrder="-1"
      responsiveLayout="scroll"
    >
      <Column field="name" header="Name" :sortable="true"></Column>
      <Column field="URL" header="URL" :sortable="true">
        <template #body="slotProps">
          <a :href="slotProps.data.URL" target="_blank">
            {{ slotProps.data.URL }}</a
          >
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import Column from "primevue/column";
import DataTable from "primevue/datatable";

export default {
  name: "InferenceRuleSets",
  components: { Column, DataTable },

  data: function () {
    return {
      enteries: [
        { name: "asEquivalent", URL: "urn:activitystreams-owl:map" },
        {
          name: "http://www.w3.org/2002/07/owl#	",
          URL: "http://www.w3.org/2002/07/owl#",
        },
        {
          name: "ldp",
          URL: "http://www.w3.org/ns/ldp#",
        },
      ],
    };
  },
};
</script>

<style></style>
