<template>
  <div class="viewTitle">RDF Views</div>
  <p class="viewTitle">How To Let Virtuoso Render Relational Data As RDF?</p>
  <p>
    By default, Virtuoso stores all RDF data in a single "RDF Storage" that
    consists of one four-column relational table containing all triples of all
    graphs.
  </p>

  <p class="viewContextBold">
    The Virtuoso instance seems to be in the default configuration now, probably
    you do not have to inspect the rest of this page.
  </p>
  <p>
    System Administrator can extend this basic schema with more storages and
    more groups of triples to represent a variety of SQL tables and views stored
    in Virtuoso or in attached data sources.
  </p>

  <p class="viewTitle">
    Storage &lt;http://www.openlinksw.com/schemas/virtrdf#DefaultQuadStorage&gt;
  </p>

  <p>This pre-defined storage is used by default by all SPARQL queries.</p>
  <p>The storage contains no RDF Views.</p>
  <p>
    The storage has a default quad map
    &lt;http://www.openlinksw.com/schemas/virtrdf#DefaultQuadMap&gt; <br />The
    quad map provides triples for various graphs named by format
    &lt;http://www.openlinksw.com/virtrdf-data-formats#default-iid-nonblank&gt;.
    <br />The data come from DB.DBA.RDF_QUAD
  </p>

  <p class="viewTitle">
    Storage
    &lt;http://www.openlinksw.com/schemas/virtrdf#DefaultServiceStorage&gt;
  </p>
  <div>
    <p>
      This pre-defined storage is used internally for SERVICE {...} clauses of
      SPARQL queries; not for regular use.
    </p>
    <p>The storage contains no RDF Views.</p>
    The storage has a default quad map
    &lt;http://www.openlinksw.com/schemas/virtrdf#DefaultServiceMap&gt;
    <br />The quad map provides triples for various graphs named by format
    &lt;http://www.openlinksw.com/virtrdf-data-formats#default-iid-nonblank&gt;.
    <br />The data come from system procedure views that compose and send HTTP
    requests to remote SPARQL service endpoints and then parse answers
  </div>

  <p class="viewTitle">
    Storage &lt;http://www.openlinksw.com/schemas/virtrdf#SyncToQuads&gt;
  </p>

  <p>
    This pre-defined storage is to enumerate quad maps mentioned by triggers
    based on RDF Views; these triggers track changes in source tables of RDF
    Views and materialize updated content of views as "physical" triples.
  </p>

  <p>The storage contains no RDF Views.</p>
  <p>The storage has no default quad map.</p>
</template>

<script>
export default {
  name: "RDFviews",
};
</script>

<style></style>
