<template>
  <div class="viewTitle">Namespaces</div>

  <div class="card">
    <DataTable
      :value="enteries"
      sortField="category"
      :sortOrder="-1"
      responsiveLayout="scroll"
    >
      <Column field="prefix" header="prefix" :sortable="true"></Column>
      <Column field="URL" header="URL" :sortable="true">
        <template #body="slotProps">
          <a :href="slotProps.data.URL" target="_blank">
            {{ slotProps.data.URL }}</a
          >
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import Column from "primevue/column";
import DataTable from "primevue/datatable";

export default {
  name: "Namespaces",
  components: { Column, DataTable },
  data: function () {
    return {
      enteries: [
        { prefix: "as", URL: "http://www.w3.org/ns/activitystreams#" },
        { prefix: "bif", URL: "bif:" },
        {
          prefix: "dawgt",
          URL: "http://www.w3.org/2001/sw/DataAccess/tests/test-dawg#",
        },
        { prefix: "dbpedia", URL: "http://dbpedia.org/resource/" },
        { prefix: "dbpprop", URL: "http://dbpedia.org/property/" },
        { prefix: "dc", URL: "http://purl.org/dc/elements/1.1/" },
        { prefix: "fn", URL: "http://www.w3.org/2005/xpath-functions/#" },
        { prefix: "foaf", URL: "http://xmlns.com/foaf/0.1/" },
        { prefix: "geo", URL: "http://www.w3.org/2003/01/geo/wgs84_pos#" },
        { prefix: "go", URL: "http://purl.org/obo/owl/GO#" },
        { prefix: "ldp", URL: "http://www.w3.org/ns/ldp#" },
        { prefix: "math", URL: "http://www.w3.org/2000/10/swap/math#" },
        { prefix: "mesh", URL: "http://purl.org/commons/record/mesh/" },
        {
          prefix: "mf",
          URL: "http://www.w3.org/2001/sw/DataAccess/tests/test-manifest#",
        },
        {
          prefix: "nci",
          URL: "http://ncicb.nci.nih.gov/xml/owl/EVS/Thesaurus.owl#",
        },
        { prefix: "obo", URL: "http://www.geneontology.org/formats/oboInOwl#" },
        { prefix: "ogc", URL: "http://www.opengis.net/" },
        { prefix: "ogcgml", URL: "http://www.opengis.net/ont/gml#" },
        { prefix: "ogcgs", URL: "http://www.opengis.net/ont/geosparql#" },
        {
          prefix: "ogcgsf",
          URL: "http://www.opengis.net/def/function/geosparql/",
        },
        { prefix: "ogcgsr", URL: "http://www.opengis.net/def/rule/geosparql/" },
        { prefix: "ogcsf", URL: "http://www.opengis.net/ont/sf#" },
        { prefix: "owl", URL: "http://www.w3.org/2002/07/owl#" },
        { prefix: "product", URL: "http://www.buy.com/rss/module/productV2/" },
        {
          prefix: "protseq",
          URL: "http://purl.org/science/protein/bysequence/",
        },
        { prefix: "rdf", URL: "http://www.w3.org/1999/02/22-rdf-syntax-ns#" },
        { prefix: "rdfa", URL: "http://www.w3.org/ns/rdfa#" },
        {
          prefix: "rdfdf",
          URL: "http://www.openlinksw.com/virtrdf-data-formats#",
        },
        { prefix: "rdfs", URL: "http://www.w3.org/2000/01/rdf-schema#" },
        { prefix: "sc", URL: "http://purl.org/science/owl/sciencecommons/" },
        { prefix: "scovo", URL: "http://purl.org/NET/scovo#" },
        {
          prefix: "sd",
          URL: "http://www.w3.org/ns/sparql-service-description#",
        },
        { prefix: "sioc", URL: "http://rdfs.org/sioc/ns#" },
        { prefix: "skos", URL: "http://www.w3.org/2004/02/skos/core#" },
        { prefix: "sql", URL: "sql:" },
        { prefix: "vcard", URL: "http://www.w3.org/2001/vcard-rdf/3.0#" },
        { prefix: "vcard2006", URL: "http://www.w3.org/2006/vcard/ns#" },
        {
          prefix: "virtcxml",
          URL: "http://www.openlinksw.com/schemas/virtcxml#",
        },
        {
          prefix: "virtrdf",
          URL: "http://www.openlinksw.com/schemas/virtrdf#",
        },
        { prefix: "void", URL: "http://rdfs.org/ns/void#" },
        { prefix: "xf", URL: "http://www.w3.org/2004/07/xpath-functions" },
        { prefix: "xml", URL: "http://www.w3.org/XML/1998/namespace" },
        { prefix: "xsd", URL: "http://www.w3.org/2001/XMLSchema#" },
        { prefix: "xsl10", URL: "http://www.w3.org/XSL/Transform/1.0" },
        { prefix: "xsl1999", URL: "http://www.w3.org/1999/XSL/Transform" },
        { prefix: "xslwd", URL: "http://www.w3.org/TR/WD-xsl" },
        { prefix: "yago", URL: "http://dbpedia.org/class/yago/" },
      ],
    };
  },
};
</script>

<style></style>
